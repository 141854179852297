import Vue from "vue";
import ElementUI from "element-ui";
//样式文件需要单独引入
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "./assets/css/style.css";
import { getRequest, postRequest } from "@/api/api";

Vue.use(ElementUI);

Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
